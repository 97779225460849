@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.TransformationContainer {
  margin-bottom: 100px;
  position: relative;
  background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 0) 100%);
}

.TransformationContent {
  @include block_radius;
  width: 1210px;
  // padding: 32px 40px 40px;
  padding: 64px $defaultPadding 107px $defaultPadding;
  background: $White;
}

.TransformationText {
  @include text();
  padding-bottom: 24px;
}

.TransformationMobile {
  position: absolute;
  right: $defaultPadding;
  // top: -19px;
  top: -149px;
}

.TransformationTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

@media screen and (max-width: 1600px) {
  .TransformationText {
    width: 880px;
  }
  .TransformationContent {
    width: 1020px;
    padding: 64px 70px;
  }
}

@media screen and (max-width: 1400px) {
  .TransformationTitle {
    font-size: 40px;
    width: 700px;
  }
  .TransformationText {
    font-size: 20px;
    width: 700px;
  }
  .TransformationContent {
    width: 800px;
    padding: 48px 40px;
  }
}

@media screen and (max-width: 1230px) {
  .TransformationTitle {
    font-size: 30px;
    width: 330px;
  }
  .TransformationText {
    font-size: 20px;
    width: 330px;
  }
  .TransformationContent {
    width: 614px;
    padding: 48px 40px;
  }
  .TransformationMobile {
    top: 7px;
  }
}

@media screen and (max-width: 850px) {
  .TransformationMobile {
    right: 0;
  }
}

// before 740px -> Mobile Transformation
@media screen and (max-width: 800px) {
  .TransformationMobile {
    position: relative;
    top: 30px;
    width: 297px;
    height: 462px;
    // right: 0;
    // display: none;
  }
  .TransformationTitle {
    width: 100%;
  }
  .TransformationText {
    width: 100%;
  }
  .TransformationContent {
    width: 100%;
    border-top-right-radius: 0px;
    padding: 48px 40px;
  }
}
