@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.Select {
  &-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &-Label {
    color: #999999;
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 22px;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }
  &-SelectContainer {
    position: relative;
    height: 40px;
    background-color: #FAFAFA;
    box-shadow: inset 0px -0.5px 0px #DADADA;
    display: flex;
    // у переключателя должен быть больше всех остальных блоков, КРОМЕ модального окна(=10)!
    // и контейнера ханивуда
    z-index: 4; 
    &:focus-within {
      outline: none !important;
    }
  }
  &-Header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
  }
  &-HeaderTitle {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
  }
  &-DropDownContainer {
    position: absolute;
    width: 100%;
    top: 40px;
    bottom: auto;
    max-height: 240px;
    &_directionOpenedListTop {
      bottom: 40px;
      top: auto
    }
  }
  &-DropDownList {
    padding: 0;
    margin: 0;
    max-height: 240px;
    overflow-y: auto;
    background: $White;
    border: 0.5px solid #DADADA;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
  }
  &-ListItem {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0px;
    height: 40px;
    padding-left: 16px;
  }
  &-ListItem:hover {
    background: #f2f2f2;
  }
}