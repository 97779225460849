@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.AcquaintanceContainer {
  @include block_radius;
  padding: 62px 250px 51px $defaultPadding;
  background-color: $LightWhite;
  width: 1080px;
}

.AcquaintanceText {
  @include text;
}

.AcquaintanceTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

@media screen and (max-width: 1420px) {
  .AcquaintanceContainer {
    width: 840px;
    padding-right: $defaultPadding;
  }
}

@media screen and (max-width: 1200px) {
  .AcquaintanceContainer {
    width: 532px;
  }
  .AcquaintanceTitle {
    font-size: 30px;
  }
  .AcquaintanceText {
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .AcquaintanceContainer {
    width: 100%;
  }
}