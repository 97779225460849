@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InputContainer {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 16px;
}

.InputLabel {
  font-size: 12px;
  line-height: 16px;

  color: $mt_text2;
}

.Input {
  padding: 2px 0 18px;
  width: 100%;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $DarkBlack;
}

.ModalContainer {
  background-color: rgba($Black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: auto;
}

.Modal {
  margin: auto;
  position: relative;
  // height: 660px;
  width: 460px;
  background: $White;
  border-radius: 15px;
  padding: 48px 48px 32px;
  box-shadow: 0px 11px 15px rgba($Black, 0.2), 0px 24px 38px rgba($Black, 0.14),
    0px 9px 46px rgba($Black, 0.12);
  transition: all 0.5s;
}

.SendButton {
  display: flex;
  justify-content: center;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.ModalTitle {
  @include title;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 21px;
  transition: all 0.5s;
}

.ModalText {
  @include text;
  font-size: 10px;
  line-height: 14px;
  color: $mt_text2;
  padding-top: 24px;
}

.ModalInvestText {
  @include text;
  font-size: 16px; // было 12px
  line-height: 130%; // было 16px
  color: $mt_text2;
  padding: 0 0 8px;
}

.ModalInvestSum {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $mt_text2;
}

.ModalInvestInput {
  padding-left: 4px;
  color: $DarkBlack;
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 5px;
  // width: 90px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}

.ModalInvestSlider {
  width: 100%;
  margin: 0 0 24px;
  cursor: pointer;
}

@media screen and (max-width: 470px) and (min-width: 360px) {
  .Modal {
    width: 345px;
    padding: 32px 25px;
  }
  .ModalTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 359.44px) {
  .Modal {
    width: 295px;
    padding: 32px 25px;
  }
  .ModalTitle {
    font-size: 24px;
  }
}

.CheckBox {
  margin-bottom: 16px;
  flex: 1;
}

.CheckBoxContainer {
  display: flex;
}
