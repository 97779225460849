@import '@src/styles/colors.scss';
@import '@src/styles/variables.scss';

@mixin title {
  font-family: Raleway;
  font-weight: 800;
  line-height: 130%;
  color: $DarkBlack;
  text-transform: uppercase;
}

@mixin text {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: $DarkBlack;
}

.ButtonEng {
  margin-top: 78px;
}

.Container {
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.MainContainer {
  // из-за этого градиента мед сверху - полупрозрачный
  background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 20%) 4%);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.ContentContainer {
  padding: 48px $defaultPadding;
  padding-right: calc(370px + #{$defaultPadding}* 2);
  position: relative;
  z-index: 3;
}

.ButtonContainer {
  // display: flex;
  width: 100%;
  text-align: -webkit-center;
  margin-top: 40px;
}

.HoneyHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.BearImage {
  position: absolute;
  bottom: -1px;
  right: 0;
  z-index: 2;
}

.TextContainerSmall {
  padding-right: calc(470px + #{$defaultPadding}* 2);
}

.StrengthsContainer {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  position: relative;
}

.StrengthPoint {
  border-radius: 7.5px;
  min-width: 15px;
  height: 15px;
  background-color: $mt_orange;
  margin-top: 10px;
  margin-right: 12px;
}

.TextMarginRight {
  margin-right: 160px;
}

.SocialContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 3;
}

.PaddingSocial {
  padding: 0 40px 40px;
  // margin-left: $defaultPadding;
}

.SocialItem {
  background-color: #3f3e39;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  // margin-left: 12px;
  // margin-right: 12px;
  margin: 8px 12px;
}

// .SocialItem:last-child {
//   margin-right: 0px;
// }

// .SocialItem:first-child {
//   margin-left: 0px;
// }

.Title {
  @include title;
  font-size: 40px;
  width: 688px;
  mix-blend-mode: multiply;
}

.Text {
  @include text;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 1420px) {
  .BearImage {
    right: -330px;
  }
  .TextMarginRight {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .ContentContainer {
    padding-right: calc(#{$defaultPadding});
  }
  .TextMarginRight {
    margin-right: 340px;
  }
}

@media (max-width: $responsive-tablet) {
  .MainContainer {
    // padding-right: $defaultPadding;
  }
  .Title {
    font-size: 40px;
    width: 100%;
  }
  .Text {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .TextMarginRight {
    margin-right: 0px;
  }
  .BearImage {
    position: relative;
    right: -40px;
  }
  // .PaddingSocial {
  //   padding: 0px 0px;
  //   padding-bottom: 40px;
  // }
  .ButtonContainer {
    justify-content: center;
  }
  .SocialContainer {
    justify-content: center;
  }
}

@media (max-width: $responsive-tablet-small) {
  .Title {
    font-size: 24px;
  }
  .Text {
    font-size: 18px;
  }
  .MainContainer {
    padding-top: 32px;
  }
}

.Envelope {
  position: relative;
  // left: -1px;
  bottom: -0.5px;
}
.Web {
  position: relative;
  left: 0.5px;
  bottom: -1px;
}
.Chat {
  position: relative;
  left: 1px;
  bottom: -1px;
}
.TelegramPlane {
  position: relative;
  left: -1px;
}
.Facebook {
  // position: relative;
  // left: -1px;
}
.Twitter {
  position: relative;
  left: 2px;
  bottom: -1px;
}

.ContentSection {
  margin-bottom: 30px;
}

.FirstTitle {
  margin-bottom: 40px;
  width: auto;
}

.LastTitle {
  margin-bottom: 40px;
  width: auto;
}
