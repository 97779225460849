@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.CoinContainer {
  position: relative;
  height: 424px;
  padding: 64px $defaultPadding 0 970px;
  background-color: $LightWhite;
}

.CoinCore {
  position: absolute;
  left: 239px;
  top: -46px;
}

.CoinText {
  @include text;
  width: 650px;
  padding: 0 0 32px;
}

.CoinTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

@media screen and (max-width: 1700px) {
  .CoinCore {
    left: calc(239px - (1700px - 100%)*1);
  }

  .CoinContainer {
    padding-left: calc(970px - (1700px - 100%)*1);
  }
}

@media screen and (max-width: 1546px) {
  .CoinCore {
    left: 70px;
    width: 616px;
  }

  .CoinContainer {
    padding-left: 731px;
  }
}

@media screen and (max-width: 1400px) {
  .CoinText {
    width: 420px;
    font-size: 20px;
  }

  .CoinTitle {
    font-size: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .CoinContainer {
    height: 368px;
    padding-left: 532px;
  }
  .CoinCore {
    width: 572px;
    top: -93px;
    left: -80px;
  }
}

@media screen and (max-width: 1000px) {
  .CoinText {
    width: 330px;
  }
  .CoinContainer {
    height: 430px;
  }
}

@media screen and (max-width: 900px) {
  .CoinCore {
    left: -202px;
  }
  .CoinContainer {
    padding-left: 390px;
  }
}

@media screen and (max-width: 750px) {
  .CoinCore {
    left: -282px;
  }
  .CoinContainer {
    padding-left: 310px;
  }
}

// before 670px CoinSection -> Mobile
@media screen and (max-width: 670px) {
  .CoinCore {
    width: 500px;
    left: 0;
    margin: auto;
    right: 0;
    top: 300px;
  }
  .CoinContainer {
    padding-left: $defaultPadding;
    height: 675px;
  }
  .CoinText {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .CoinContainer {
    height: 760px;
    overflow: hidden;
  }
  .CoinCore {
    width: 500px;
    left: 0;
    margin: auto;
    right: 0;
    top: 310px;
  }
}