@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.Input {
  &-Label {
    color: #999999;
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 22px;
    margin: 0;
  }
  &-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
    &_canError {
      min-height: 120px;
    }
    &_isFewInputsRow:first-child {
      margin-right: 48px;
    }
  }
  &-Error {
    margin: 0;
    padding: 0;
    @include font($size: 16px, $family: Roboto, $weight: 400);
    color: #ff8a00;
  }
  &-Input {
    @include input;
    width: -webkit-fill-available;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none;
  }
  &-Input:focus {
    outline: none !important;
  }
  &-InputContainer {
    padding: 1px 1px;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 0px;
    background-color: $Alabaster;
    box-shadow: inset 0px -0.5px 0px $Alto;
    border: 1px solid transparent;
    &_canError {
      border: 1px solid $Alabaster;
      margin-bottom: 8px;
    }
    &_readOnly {
      border: none;
    }
  }
  &-InputContainer:focus-within {
    border: 1px solid $Black;
  }
  &-InputContainer_readOnly:focus-within {
    border: none;
  }
  &-InputButtonShow {
    border: none;
    background-color: $Alabaster;
    cursor: pointer;
    & p {
      color: #999999;
      font-size: 14px;
      line-height: 22px;
      font-family: Roboto;
      margin: 0;
    }
  }
}
