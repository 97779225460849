@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.RoadMapContainer {
  position: relative;
  // height: 1435px;
  padding: 64px 135px 77px 190px;
  background-color: $LightWhite;
  transition: all 0.5s;
}

.RoadMapTextContainer {
  width: 610px;
  transition: all 0.5s;
  margin-bottom: 32px;
}

.RoadMapTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 16px;
  transition: all 0.5s;
}

.RoadMapText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  transition: all 0.5s;
}

.Mobile {
  &RoadMapDashBoard {
    display: flex;
    transition: all 0.5s;
  }
  &RoadMapEvents {
    display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 8px;
    position: relative;
    left: -21px;
  }
  &RoadMapEvent {
    margin-bottom: 32px;
  }
  &RoadMapEventTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $DarkBlack;
    margin-bottom: 4px;
  }
  &RoadMapEventText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: $DarkGrayish;
    max-width: 300px;
  }
  &RoadMapSvgContainer {
  }
  &RoadMap {
    position: relative;
    left: -21px;
  }
}

@media screen and (max-width: 1419.99px) and (min-width: 1024px) {
  .RoadMapText {
    width: 350px;
  }
}
@media screen and (max-width: 1023.99px) and (min-width: 760px) {
  .RoadMapText {
    width: 350px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1420px) {
  .RoadMapContainer {
    padding: 64px 70px 45px;
  }
}
@media screen and (max-width: 1419px) and (min-width: 400px) {
  .RoadMapContainer {
    padding: 48px 40px;
  }
}

@media screen and (max-width: 1419px) and (min-width: 520px) {
  .RoadMapTextContainer {
    width: 457px;
  }
}
@media screen and (max-width: 519px) and (min-width: 450px) {
  .RoadMapTextContainer {
    width: 380px;
  }
}
@media screen and (max-width: 449px) and (min-width: 400px) {
  .RoadMapTextContainer {
    width: 350px;
  }
}

@media screen and (max-width: 1419px) and (min-width: 760px) {
  .RoadMapTitle {
    font-size: 30px;
  }

  .RoadMapText {
    font-size: 20px;
  }
}
@media screen and (max-width: 760px) and (min-width: 400px) {
  .RoadMapTitle {
    font-size: 24px;
  }

  .RoadMapText {
    font-size: 18px;
  }
  .RoadMapContainer {
    // height: 1387px;
  }
}
