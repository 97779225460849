@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.BlockchainGameContainer {
  max-width: 1600px;
  position: relative;
  background: #bfd447;
  border-radius: 30px;
  margin: 124px var(--default-padding) 140px;
}
.InfoBlockContainer {
  // background: #bfd447;
  // border-radius: 30px 0 0 30px;
  width: 500px;
  position: relative;
  z-index: 1;
  // left: 9px;
  // padding: 0 0 0 60px;
  padding-left: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.BeeLeft {
  width: 94px;
  position: absolute;
  left: 40px;
  top: -50px;
}
.BeeRight {
  width: 137px;
  position: absolute;
  bottom: -45px;
  left: 600px;
}

.InfoBlockTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #424242;
  @include textBorder_3px_shadow(#ffffff);
  margin-bottom: 20px;
}
.InfoBlockSubTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-bottom: 33px;
}
.JoinButtonContainer {
  position: relative;
  top: 45px;
}
.JoinButtonButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
  @include textBorder_3px_shadow(#a24304);
}
.BlockchainGameImageXL {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 30px 30px 0;
}
.BlockchainGameImage {
  display: none;
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  border-radius: 30px 30px 0 0;
}
.HoneywoodLogoXL {
  width: 228px;
  margin-top: 50px;
  margin-bottom: 5px;
}
.HoneywoodLogo {
  display: none;
  position: absolute;
  width: 228px;
  top: 40px;
  right: 40px;
  display: none;
}
@media screen and (max-width: 1640px) and (min-width: 960px) {
  .BlockchainGameImageXL {
    display: none;
  }
  .BlockchainGameImage {
    display: initial;
  }
  .HoneywoodLogo {
    display: initial;
  }
  .HoneywoodLogoXL {
    display: none;
  }
  .JoinButtonContainer {
    top: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    // width: 300px;
    // height: 80px;
  }
  .InfoBlockTitle {
    position: absolute;
    bottom: 100%;
    left: 52px;
    flex: 1;
  }
  .InfoBlockContainer {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 40px 28px 50px;
  }
  .InfoBlockSubTitle {
    text-align: left;
    max-width: 540px;
    margin-right: 40px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1470px) and (min-width: 960px) {
  .BeeRight {
    width: 95px;
    bottom: 140px;
    left: unset;
    right: -20px;
  }
}
@media screen and (max-width: 959.99px) and (min-width: 570.01px) {
  .BeeRight {
    width: 95px;
    bottom: 320px;
    left: unset;
    right: -20px;
  }
}
@media screen and (max-width: 959.99px) and (min-width: 570.01px) {
  .BlockchainGameImageXL {
    display: none;
  }
  .BlockchainGameImage {
    display: initial;
  }
  .HoneywoodLogo {
    display: initial;
  }
  .HoneywoodLogoXL {
    display: none;
  }
  .JoinButtonContainer {
    top: unset;
    // width: 300px;
    // height: 80px;
  }
  .JoinButtonButtonTitle {
    // font-family: 'Fira Sans';
    // font-style: normal;
    // font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    // color: #ffffff;
    // @include textBorder_3px_shadow(#A24304);
  }
  .InfoBlockTitle {
    position: initial;
    bottom: initial;
    left: initial;
  }
  .InfoBlockContainer {
    flex-direction: column;
    width: 100%;
    // justify-content: space-between;
    padding: 0 40px 28px 50px;
  }
  .InfoBlockSubTitle {
    text-align: center;
    // max-width: 540px;
    margin-right: 0;
    margin-bottom: 33px;
  }
}
