@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PrincipleContainer {
  padding: 64px 0 94px $defaultPadding;
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
}

.PrincipleText {
  @include text;
  width: 760px;
  padding-bottom: 32px;
  transition: all 0.5s;
}

.PrincipleTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
  transition: all 0.5s;
}

.PrincipleTaxiContainer {
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
}

.PrincipleAggregatorContainer {
  background-color: $mt_yellow;
  width: 110px;
  height: 341px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-left: 30px;
  transition: all 0.5s;
}

.PrincipleAggregatorHash {
  @include title;
  font-weight: bold;
  font-size: 30px;
  line-height: 136%;
  color: $White;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.PrincipleAggregatorTitle {
  color: $Black;
  font-size: 26px;
}

.PrincipleProfitContainer {
  margin-left: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s;
}

.PrincipleProfitText {
  font-family: Roboto;
  font-size: 18px;
  line-height: 140%;
  color: $DarkBlack;
  margin-bottom: 16px;
}

.ProfitImageBefore {
  margin-bottom: 28px;
}

@media screen and (max-width: 1600px) {
  .PrincipleText {
    width: 620px;
  }
}

@media screen and (max-width: 1400px) {
  .PrincipleTitle {
    font-size: 30px;
  }
  .PrincipleText {
    font-size: 20px;
    line-height: 160%;
    width: 550px;
  }
}

@media screen and (max-width: 1300px) {
  .PrincipleContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .PrincipleText {
    width: 100%;
    padding-right: $defaultPadding;
  }
}

@media screen and (max-width: 760px) {
  .PrincipleProfitContainer {
    margin-left: 24px;
  }
}

// before 700px Principle Section -> Mobile
@media screen and (min-width: 570px) {
  .PrincipleAggregatorContainer {
    width: 90px;
    margin-left: 0;
  }
}
// @media screen and (max-width: 550px) {
//   .PrincipleAggregatorContainer {
//     display: none;
//   }
// }

.PrincipleServiceCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: center;
  // // margin-left: 40px;
  // transition: all 0.5s;

  // justify-content: center;
  margin-left: 40px;
}
.PrincipleServiceCommissionDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: $Gray;
  opacity: 0.7;
}
.PrincipleServiceCommissionText {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  color: $Gray;
  opacity: 0.7;
}
.PrincipleTaxiCompanyCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: center;
  position: relative;
  top: -6px;
  margin-left: 80px;
  // left: 65px;
  transition: all 0.5s;
}
.PrincipleTaxiCompanyCommissionDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $Gray;
  opacity: 0.5;
}
.PrincipleTaxiCompanyCommissionText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  color: $Gray;
  opacity: 0.5;
}
.PrincipleTaxesCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: center;
  position: relative;
  top: -10px;
  margin-left: 105px;
  // left: -10px;
  transition: all 0.5s;
}
.PrincipleTaxesDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $Gray;
  opacity: 0.4;
}
.PrincipleTaxesText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $Gray;
  opacity: 0.4;
}
