$White: #ffffff;
$LightWhite: #F9F9F9;
$DarkGray: #454545;
$BrownBlack: #3f3e39;
$DarkGrayish: #7E7D74;
$Gray: #9c9a8e;
$LightGray: #ada595;
$DarkBlack: #2a2924;
$Black: #000000;
$Brown: #87785e;
$Milk: #e5dac6;
$mt_yellow: #f9da4c;
$mt_superyellow: #FFD336;
$Yellow: #F2C94C;
$YellowLight: #FFFBF0;
$YellowGray: #eedf989f;
$SuperYellow: #FFD336;
$mt_text2: #AEAB9E;
$mt_orange: #FFB600;

$Alabaster: #fafafa; // input-bg
$Alto: #dadada; // input-box-shadow
$Alto2: #d9d9d9; // input-box-shadow
$Tundora: #444444; // input-text
$Turquoise: turquoise; // Spinner
$Shamrock: #2bc09a; // sidebar-bg & main_buttons

// from HoneyWood
$white: #ffffff;
$black: #000000;
$dark_black: #2A2A2A;
$dark_gray: #3C3C3C;
$dark_gray_33: #333333;
$gray: #ABABAB;
$light_gray_EA: #EAEAEA;
$light_gray_BE: #BEBEBE;

$blue: #3a4472;
$blue_green: #497782;
$orange: #f9ab07;
$orange_light: #FFC00A;

$green_pea: #1e5629;
$green_pea2: #276333;
$green_pea3: #246916;
$green_christi: #71b60b;
$japanese_laurel: #0A7604;
$green_marsh: #7BA129;

$yellow_green: #bfd447;
$green_yellow: #9ed427;
$lawn_green: #61ab10;
$lime_green: #347942;
$brown_dark: #424242;
$light_green: #8fb897;
$lime_light_green: #c2e2b2;
$dark_green: #083210;

$cinnabar: #e75b37;
$cognac: #aa3b1e;
$prairie_sand: #97371f;
$chocolate: #A24304;
$chocolate_red: #A94025;
$dark_tomato: #852F19;
$red: #FB4517;
$shiraz: #B50E38;

$dark_orchid: #9E0085;
$dark_violet: #8507BA;

$flamingo: #FCBFBF;
$watermelon: #F68B8B;

$alabaster: #fafafa; // input-bg
$alto: #dadada; // input-box-shadow
$tundora: #444444; // input-text
$light_gray : #DFDFDF;