@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.HoneyWoodContainer {
  @include block_radius;
  padding: 62px 130px 51px $defaultPadding;
  background-color: $White;
  width: 1210px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.HoneyWoodImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.Bee4Image {
  position: absolute;
  top: 44px;
  left: 80px;
  z-index: 3;
}

.Bee3Image {
  position: absolute;
  top: 126px;
  right: 240px;
  z-index: 3;
}

.HoneyWoodContentContainer {
  position: relative;
  z-index: 2;
}

.HoneyWoodText {
  @include text;
  mix-blend-mode: multiply; 
}

.HoneyWoodTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.HoneyWoodSecondTitle {
  @include title;
  padding-bottom: 16px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
}

.HoneyWoodNavigationContainer {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
}

.HoneyWoodTitleCard {
  width: 250px;
}

@media screen and (max-width: 1920px) {
  .Bee4Image {
    top: 24px;
    left: 20px;
  } 
  .Bee3Image {
    top: 126px;
    right: 140px;
  }
}

@media screen and (max-width: 1420px) {
  .HoneyWoodContainer {
    width: 970px;
    padding-right: $defaultPadding;
  }
  .Bee4Image {
    top: 24px;
    left: 20px;
  } 
  .Bee3Image {
    top: 126px;
    right: 140px;
  }
}

@media screen and (max-width: 1200px) {
  .HoneyWoodContainer {
    width: 662px;
  }
  .HoneyWoodTitle {
    font-size: 30px;
  }
  .HoneyWoodText {
    font-size: 20px;
  }
  .BeeArrowImage {
    display: none;
  }
  .Bee4Image {
    top: 10px;
    left: 20px;
  } 
  .Bee3Image {
    top: 96px;
    right: 80px;
  }
}

@media screen and (max-width: 680px) {
  .HoneyWoodContainer {
    width: 552px;
  }
  .HoneyWoodTitle {
    font-size: 30px;
  }
  .HoneyWoodText {
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .HoneyWoodContainer {
    width: 100%;
  }
}