@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.BigButtonContainer {
  cursor: pointer;
  border: none;
  height: 80px;
  width: 300px;
  position: relative;
  border-radius: 50px;
  background: $mt_superyellow;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  &:active {
    @include click-animation;
  }
}

.BigButtonShadow {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
  height: 71px;
  width: 294px;
  background: $mt_superyellow;
}

.BigButtonLightShadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background: $White;
  mix-blend-mode: lighten;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  opacity: 0.2;
}

.BigButtonContainerMedium {
  height: 50px;

  .BigButtonShadow {
    height: 50px;
  }
}

.BigButtonText {
  position: relative;
  display: flex;
  justify-content: center;
  color: $DarkBlack;
}

@media screen and (max-width: 500px) {
  .BigButtonContainer {
    width: 290px;
  }
  .BigButtonShadow {
    width: 284px;
  }
}

@media screen and (max-width: 360px) {
  .BigButtonContainer {
    width: 250px;
  }
  .BigButtonShadow {
    width: 244px;
  }
}

@media screen and (max-width: 300px) {
  .BigButtonContainer {
    width: calc(300px - (300px - 100%));
  }
  .BigButtonShadow {
    width: calc(294px - (300px - 100%));
  }
}
