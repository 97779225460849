@import '@src/styles/colors.scss';
@import '@src/styles/variables.scss';

@mixin title {
  font-family: Raleway;
  font-weight: 800;
  line-height: 130%;
  color: $DarkBlack;
  text-transform: uppercase;
}

@mixin text {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: $DarkBlack;
}

.Container {
  // text-align: center;
  padding: 20px;
  background-color: #F9F9F9;
  padding-left: var(--padding-small);
  padding-right: var(--padding-small);
}

.descriptionHead {
  font-size: 14;
  font-weight: 600;
}

.h1 {
  margin-bottom: 20px;
  @include title;
  font-size: 30px;
  text-transform: capitalize;
}

.h2 {
  @include title;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.p {
  @include text;
  font-size: 40px;
  margin-bottom: 15px;
  font-size: 18px;
}
.pointerP {
  @include text;
  font-size: 40px;
  margin-bottom: 15px;
  font-size: 18px;
  padding-left: 24px;
}

@media (min-width: 500px) {
  .Container {
    padding-left: var(--padding-small);
    padding-right: var(--padding-small);
  }
}

@media (min-width: 768px) {
  .Container {
    padding-left: var(--padding-small-medium);
    padding-right: var(--padding-small-medium);
  }
}

@media (min-width: 1200px) {
  .Container {
    padding-left: var(--padding-medium);
    padding-right: var(--padding-medium);
  }
  .p, .pointerP {
    font-size: 20px;
  }
  .h1 {
    font-size: 36px;
  }
  .h2 {
    font-size: 30px;
  }
}

@media (min-width: 1600px) {
  .Container {
    padding-left: var(--padding-large);
    padding-right: var(--padding-large);
  }
  .p, .pointerP {
    font-size: 22px;
  }
  .h1 {
    font-size: 40px;
  }
  .h2 {
    font-size: 36px;
  }
}