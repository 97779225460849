@import '@src/styles/colors.scss';
@import '@src/styles/variables.scss';

@mixin title {
  font-family: Raleway;
  font-weight: 800;
  line-height: 130%;
  color: $DarkBlack;
  text-transform: uppercase;
}

@mixin text {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: $DarkBlack;
}

.MainContainer {
  background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 0) 100%);
  padding: 48px $defaultPadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-right: calc(370px + #{$defaultPadding}*2);
}

.Title {
  @include title;
  font-size: 40px;
  width: 688px;
  text-align: center;
}

.Subtitle {
  @include title;
  font-size: 30px;
  // width: 516px;
}

.Button {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: $DarkBlack;
}

.AppButtonContainer {
  background-color: #ffa925;
  border-radius: 10px;
}
.AppButtonLink {
  background-color: #ffa925;
  border-radius: 10px;
  
  padding: 8px;
}

.Text {
  @include text;
  padding-top: 16px;
}

.Content {
  padding: 0 $defaultPadding;
  padding-right: calc(370px + #{$defaultPadding}*2);
}

.Other {
  padding: 64px $defaultPadding 96px $defaultPadding;
}

.Navigation {
  display: flex;
  flex-flow: column;
  gap: 24px;
  position: absolute;
  right: $defaultPadding;
  top: 587px;
}

@media (max-width: $responsive-tablet) {
  .Content {
    padding-right: $defaultPadding;
  }

  .MainContainer {
    padding-right: $defaultPadding;
  }

  .Title {
    font-size: 40px;
    width: 100%;
  }

  .Subtitle {
    font-size: 30px;
    width: 100%;
  }

  .Text {
    width: 100%;
  }

  .Navigation {
    flex-flow: row;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 64px;
    justify-content: center;
  }
}

@media (max-width: $responsive-tablet-small) {
  .Title {
    font-size: 24px;
  }

  .Subtitle {
    font-size: 20px;
  }

  .Text {
    font-size: 18px;
  }

  .MainContainer {
    padding-top: 32px;
  }

  .Other {
    padding-bottom: 48px;
  }
}

@media (max-width: 730px) {
  .Navigation {
    flex-flow: column;
    align-items: center;
  }
}