@import '@src/styles/colors.scss';

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.SpinnerComponent {
  &-Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  &-Div {
    border: 0.125em solid $Turquoise;
    border-radius: 50%;
    border-right-color: transparent;
    width: auto;
    height: auto;
    min-width: 1em;
    min-height: 1em;
    animation: 1s linear infinite spin;
  }
}
