@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.Card {
  position: relative;
  overflow: hidden;
  padding: 32px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 136%;
  border-radius: 15px;
  height: 250px;
  margin: 12px 15px; // вместо gap
  width: 100%;
}

.Clickable {
  &:active {
    @include click-animation;
  }
}

.SmallSize {
  min-width: 295px;
  width: 295px;
  min-height: 200px;
  height: 200px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 136%;
}

// Размер для карточек в разрешении 375px в блоке Moneta.Coin & Экономика
.EconomyCoinSize {
  // min-width: 100%;
  // width: 100%;
  min-height: 250px;
  height: 250px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 136%;
}
.MediumSize {
  min-width: 395px;
  width: 395px;
}

.BigSize {
  min-width: 500px;
  width: 500px;
}

@mixin yellowText {
  color: $SuperYellow;
}

._black {
  background-color: $DarkBlack;
  color: $YellowLight;
}

._gray {
  @include yellowText;
  background-color: $BrownBlack;
}

._yellow {
  background-color: $mt_yellow;
  color: $DarkBlack;
}

.Text {
  position: relative;
}

.WhiteTitle {
  color: $White;
}

.SecondHoneyWoodTitle {
  color: $mt_yellow !important;
  text-transform: uppercase;
  margin-top: 5px;

  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 136% !important;
}

.ThirdHoneyWoodTitle {
  position: relative;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: $Milk;
}

.Icon {
  // display: flex;
  // justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  // width: 100%;
}
.MainnetIcon {
  // top: 0;
  position: absolute;
  right: 0;
  width: 126%;
  height: 100%;
}
.TopZero {
  top: 0;
}

.BearCard {
  width: 100%;
  height: auto;
  bottom: 0;
  top: auto;
}
// .MainnetIconBig {
//   // top: 0;
//   position: absolute;
//   right: 0;
//   width: 100%;
//   height: 100%;
// }

.BurnDescription {
  position: relative;
  color: $White;
  font-size: 18px;
  line-height: 136%;
}

.TextHighlight {
  @include yellowText;
}

@media screen and (max-width: 560px) {
  .SmallSize {
    width: 260px;
    min-width: 260px;
  }
}

@media screen and (max-width: 1420px) {
  .Card {
    max-width: 290px;
  }
}