@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InfoContainer {
  display: flex;
  justify-content: center;
  padding: 89px $defaultPadding 101px;
  background-color: $LightWhite;
}

.InfoCardsContainer {
  @include scroll;
  display: flex;
  // gap: 20px;
  justify-content: space-between;
  // width: 100%;
  margin: 0 20px 0 20px;
}

.InfoButton {
  display: none;
}

@media screen and (max-width: 1700px) {
  .InfoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: $mt_yellow;
    min-width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  .InfoButton:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .InfoButtonImage {
    display: flex;
    width: 17px;
    height: 35px;
  }
}

@media screen and (max-width: 760px) {
  .InfoCardsContainer {
    // gap: 55px;
    margin: 0 115px 0 115px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}

@media screen and (max-width: 700px) {
  .InfoCardsContainer {
    margin-left: 10px;
    margin-right: 10px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}

@media screen and (max-width: 560px) {
  .InfoCardsContainer {
    margin-left: 10px;
    margin-right: 10px;
    // gap: 90px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}
