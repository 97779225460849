@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

@mixin title {
  font-family: 'Raleway';
  font-weight: 800;
  line-height: 130%;
  color: $DarkBlack;
}

@mixin text {
  font-size: 22px;
  line-height: 180%;
  color: $DarkGrayish;
}

@mixin subtitle {
  @include title;
  font-size: 40px;
}

@mixin block_radius {
  border-top-right-radius: 90px;
}

.BigButtonContainer {
  cursor: pointer;
  border: none;
  height: 80px;
  width: 300px;
  position: relative;
  border-radius: 50px;
  background: $Yellow;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  transition: all 0.5s;

  &:active {
    @include click-animation;
  }
}

.BigButtonShadow {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
  height: 71px;
  width: 294px;
  background: $mt_superyellow;
}

.BigButtonLightShadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background: $White;
  mix-blend-mode: lighten;
  opacity: 0.2;
}

.BigButtonContainerMedium {
  height: 50px;
  font-size: 18px;
  line-height: 21px;

  .BigButtonShadow {
    height: 50px;
  }
}

.BigButtonText {
  position: relative;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.InputContainer {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 16px;
}

.InputLabel {
  font-size: 12px;
  line-height: 16px;

  color: $mt_text2;
}

.Input {
  padding: 2px 0 18px;
  width: 100%;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $DarkBlack;
}

.ModalContainer {
  background-color: rgba($Black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: auto;
}

.Modal {
  margin: auto;
  position: relative;
  width: 345px;
  // height: 660px;
  background: $White;
  border-radius: 15px;
  padding: 32px 25px;
  box-shadow: 0px 11px 15px rgba($Black, 0.2), 0px 24px 38px rgba($Black, 0.14),
    0px 9px 46px rgba($Black, 0.12);
}

.SendButton {
  display: flex;
  justify-content: center;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.ModalTitle {
  @include title;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 21px;
}

.ModalText {
  @include text;
  font-size: 10px;
  line-height: 14px;
  color: $mt_text2;
  padding-top: 24px;
}

.ModalInvestText {
  @include text;
  font-size: 13px;
  line-height: 130%;
  color: $mt_text2;
  padding: 16px 0 8px;
}

.ModalInvestSum {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $mt_text2;
}

.ModalInvestInput {
  padding-left: 4px;
  color: $DarkBlack;
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 5px;
  // width: 100px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}

.ModalInvestSlider {
  width: 100%;
  margin: 0 0 24px;
  cursor: pointer;
}

.Services {
  position: relative;
  // height: 808px;
  background-color: $LightWhite;
  // padding: 8px 13px 48px 40px;
  // padding: 96px $defaultPadding;
}

.ServicesTitle {
  @include title;
  text-transform: uppercase;
  font-size: 18px;
  // width: 910px;
  margin-bottom: 16px;
  // word-break: break-all;
}

.ServicesText {
  @include text;
  padding: 0 0 24px;
  font-size: 18px;
  line-height: 180%;
  color: $DarkGrayish;
  margin-bottom: 250px;
  /* Чтобы текст был выше SVGшки */
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 420px) and (min-width: 350px) {
  .ServicesText {
    margin-bottom: 300px;
  }
}

.ServicesContent {
  position: relative;
  padding: 8px 13px 0px 40px;
}

.SolarSystem {
  position: absolute;
  right: 0;
  top: 50px;
}

.SolarSystemEng {
  top: 40px;
}

@media screen and (max-width: 420px) {
  .SolarSystem {
    top: 100px;
  }
}

.TransformationContainer {
  position: relative;
  background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 0) 100%);
}

.TransformationContent {
  @include block_radius;
  // width: 1210px;
  padding: 32px 40px 15px;
  background: $White;
}

.TransformationText {
  @include text();
  font-size: 18px;
  line-height: 160%;
  padding-bottom: 16px;
}

.TransformationMobile {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  img {
    width: calc(100% - 40px);
  }
}

.HoneyWoodContainer {
  background-color: $White;
  position: relative;
  display: flex;
  flex-direction: column;
}

.HoneyWoodImageContainer {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.HoneyWoodImageHeader {
  width: 100%;
}

.HoneyWoodTextContainer {
  z-index: 1;
  padding: 32px 40px;
}

.HoneyWoodCardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
  padding: 0 20px 48px;
}

.HoneyWoodCard {
}

.AcquaintanceContainer {
  @include block_radius;
  padding: 32px 40px;
  background-color: $LightWhite;
  // width: 100%;)
}

.AcquaintanceText {
  @include text;
  font-size: 18px;
  line-height: 160%;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 20px 48px;
  background-color: $LightWhite;
}

.LinkCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PrincipleContainer {
  padding: 32px 40px 50px;
  position: relative;
}

.PrincipleText {
  @include text;
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 32px;
  // width: 760px;
  // height: 258px;
}

.PrincipleAggregator {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}

.PrincipleAggregatorSharp {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 136%;
  color: $mt_superyellow;
}

.PrincipleAggregatorTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 136%;
  color: $DarkBlack;
}

.PrincipleAggregatorText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: $DarkBlack;
  margin-bottom: 16px;
}

.TaxiBefore {
  position: relative;
  margin-bottom: 33px;
  width: 100%;
}

.TaxiAfter {
  position: relative;
  width: 100%;
}

.CoinContainer {
  overflow: hidden;
  position: relative;
  height: 671px;
  padding: 32px 40px 48px;
  background-color: $LightWhite;
}

.CoinTextContainer {
  // padding: 0px 40px 0px;
}

.CoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CoinCore {
  // position: relative;
  // left: -40px;
  // top: 40px;
}

.CoinText {
  @include text;
  // width: 650px;
  padding: 0 0 32px;
  font-size: 18px;
  line-height: 160%;
}

.CoinLink {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 48px;
}

.EconomyContainer {
  // position: relative;
  // top: -178px;
  // height: 502px;
  display: flex;
  flex-direction: column;
  padding: 32px 40px 48px 40px;
  background-color: $White;
}

.AcquaintanceTitle,
.PrincipleTitle,
.TransformationTitle,
.CoinTitle,
.EconomyContainerTitle {
  @include title;
  font-size: 24px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.EconomyCards {
  // @include scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 8px;
}

.RoadMapContainer {
  // position: relative;
  // top: -178px;
  // height: 796px;
  padding: 32px 21px 16px 40px;
  background-color: $LightWhite;
}

.RoadMapDashBoard {
  display: flex;
}

.RoadMapTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 16px;
}

.RoadMapText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: $DarkGrayish;
  margin-bottom: 32px;
}

.RoadMapEvents {
  flex: 20;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  // gap: 8px 20px;
  margin-left: 8px;
  position: relative;
  left: -21px;
}

.RoadMapEvent {
  margin-bottom: 32px;
  // min-width: 370px;
  // max-width: 370px;
  // padding-top: 48px;
  // padding-left: 60px;
}

.RoadMapEventTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $DarkBlack;
  margin-bottom: 4px;
}

.RoadMapEventText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: $DarkGrayish;
}

.RoadMapSvgContainer {
  flex: 1;
}

.Roadmap {
  position: relative;
  left: -21px;
  // position: absolute;
  // width: calc(100% - 280px);
  // top: 294px;
}

.InvestTimeAndMoneyContainer {
  // background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 0) 152.08%);
}

.InvestTimeAndMoney {
  // position: relative;
  // top: -178px;
  // @include block_radius;
  // height: 360px;
  // width: 950px;
  padding: 32px 40px 48px;
  background-color: $White;
}

/* Костя, а зачем 10px по бокам? 
В итоге тоже передал, кнопка прижимается к краю, если не сделать */
.MobileButtonContainer {
  // padding: 0px 10px 48px 10px;
  // padding-bottom: 48px;
  display: flex;
  justify-content: center;
  padding: 8px 40px 48px;
}

.InvestTimeAndMoneyTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 17px;
}

@media screen and (max-width: 300px) {
  .AcquaintanceTitle,
  .PrincipleTitle,
  .TransformationTitle,
  .CoinTitle {
    @include title;
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 16px;
  }

  .EconomyContainer {
    padding: 32px 20px 48px 20px;
    background-color: $White;
  }

  .EconomyContainerTitle {
    @include title;
    font-size: 24px;
    text-transform: uppercase;
    padding-bottom: 16px;
    padding-left: 30px;
  }
}

.PrincipleServiceCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: center;
  margin-left: 40px;
  transition: all 0.5s;
}
.PrincipleServiceCommissionDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: $Gray;
  opacity: 0.7;
}
.PrincipleServiceCommissionText {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  color: $Gray;
  opacity: 0.7;
}
.PrincipleTaxiCompanyCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: flex-end;
  position: relative;
  top: -6px;
  margin-left: 80px;
  transition: all 0.5s;
}
.PrincipleTaxiCompanyCommissionDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $Gray;
  opacity: 0.5;
}
.PrincipleTaxiCompanyCommissionText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  color: $Gray;
  opacity: 0.5;
}
.PrincipleTaxesCommissionContainer {
  display: flex;
  align-items: baseline;
  // justify-content: center;
  position: relative;
  top: -10px;
  margin-left: 105px;
  transition: all 0.5s;
}
.PrincipleTaxesDigits {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $Gray;
  opacity: 0.4;
}
.PrincipleTaxesText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $Gray;
  opacity: 0.4;
}

.PrincipleProfitText {
  font-family: Roboto;
  font-size: 18px;
  line-height: 140%;
  color: $DarkBlack;
  margin-bottom: 16px;
}

@media screen and (max-width: 440px) {
  .PrincipleServiceCommissionContainer {
    margin-left: 20px;
  }
  .PrincipleTaxiCompanyCommissionContainer {
    margin-left: 40px;
  }
  .PrincipleTaxesCommissionContainer {
    margin-left: 60px;
  }
}
@media screen and (max-width: 395px) {
  .PrincipleServiceCommissionContainer {
    margin-left: 0px;
  }
  .PrincipleTaxiCompanyCommissionContainer {
    margin-left: 10px;
  }
  .PrincipleTaxesCommissionContainer {
    margin-left: 20px;
  }
}

.BlockchainGameContainer {
  position: relative;
  background: #bfd447;
  border-radius: 30px;
  margin: 80px var(--default-padding);
}
.InfoBlockContainer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px 16px;
}
.BeeLeft {
  width: 62px;
  position: absolute;
  left: 40px;
  top: -40px;
  z-index: 1;
}
.BeeRight {
  position: absolute;
  width: 49px;
  bottom: -18px;
  right: 35px;
}
.InfoBlockTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #424242;
  @include textBorder_3px_shadow(#ffffff);
  margin-bottom: 20px;
}
.InfoBlockSubTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 20px;
}
.JoinButtonContainer {
  position: absolute;
  // bottom: -33%;
  bottom: -65px;
}
.JoinButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  @include textBorder_1px_shadow(#a24304);
}
.BlockchainGameImage {
  width: 100%;
  position: relative;
  top: -2px;
}
.HoneywoodLogo {
  position: absolute;
  width: 180px;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
.PlainDeepBlueBox {
  position: relative;
  background: #425bfd;
  height: 66px;
  border-radius: 30px 30px 0 0;
  z-index: 1;
}
.ButtonContainer {
  width: 250px;
}
.ButtonText {
  font-size: 18px;
}

@media screen and (max-width: 569.99px) and (min-width: 435px) {
  .BeeRight {
    right: -21px;
    bottom: 100px;
  }
}
@media screen and (max-width: 434.99px) and (min-width: 350px) {
  .BeeRight {
    right: -21px;
    bottom: 100px;
  }
  .ButtonContainer {
    width: 200px;
  }
  .JoinButtonContainer {
    // bottom: -10%;
    bottom: -65px;
  }
}
@media screen and (max-width: 349.99px) and (min-width: 0) {
  .BeeRight {
    right: -21px;
    bottom: 100px;
    z-index: 1;
  }
  .ButtonContainer {
    width: 150px;
  }
  .ButtonText {
    font-size: 14px;
  }
  .JoinButtonContainer {
    // bottom: -9%;
    bottom: -65px;
  }
}
