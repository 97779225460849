@import '@src/styles/colors.scss';
@import '@src/styles/variables.scss';

.MainContainer {
  color: $Gray;
  height: 100vh;

  background-color: $BrownBlack;
  background-repeat: no-repeat;
  background-position: right bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: $White;
    font-size: 18px;
    text-align: center;
  }

  .MainInnerContainer {
    max-width: 500px;
    
    padding: 48px $defaultPadding;
  }

  .AppLinks {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;

    .AppStoreLink {
    }
    .GooglePlayLink {
    }
  }

  .QRContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .Link {
    color: $Gray;
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    padding: 8px;
  }
}