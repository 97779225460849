@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.CheckBoxComponent {
  &-Container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &_disabled {
      cursor: default;
    }
    &:focus-within {
      outline: none !important;
    }
  }

  &-OuterCircle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid $Alto2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    transition: all 0.1s linear;
    &_selected {
      border: 1px solid $Shamrock;
    }
  }

  &-InnerCircle {
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $Shamrock;
    transition: all 0.1s linear;
    &_selectedCircle {
      width: 8px;
      height: 8px;
    }
  }

  &-Title {
    padding: 0 8px;
    // padding-right: 8px;
    // padding-left: 16px;
    @include font();
    color: $Tundora;
    &_selected {
      @include font();
    }
    &_disabled {
      color: #999999;
    }
  }
}
