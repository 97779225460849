@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.LayoutComponent {
  background-color: $White;
}

.BackContainer {
  display: flex;
  padding: 48px 0 0 $defaultPadding;
  font-size: 18px;
  line-height: 25px;
  background: $LightWhite;
}

.Back {
  display: flex;
  align-items: center;
  color: $DarkGrayish;

  &:active {
    @include click-animation;
  }
}

.BackIcon {
  margin-right: 16px;
}
.CookiesPopUpBlock {
  z-index: 10;
  position: fixed;
  bottom: 35px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
}

a.PrivacyAndTermsLink {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  a.PrivacyAndTermsLink {
    font-size: 16px;
    line-height: 18px;
  }
}
