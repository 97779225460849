@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500;600;800;900&display=swap');
@import '@src/styles/reset.css';

:root {
  --padding-small: 30px;     /* Отступ для маленьких экранов */
  --padding-small-medium: 60px; /* Отступ для маленьких-средних экранов */
  --padding-medium: 80px;    /* Отступ для средних экранов */
  --padding-large: 150px;     /* Отступ для больших экранов */
}

body,
html {
  font-family: 'Open Sans';
  font-size: 10px;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

p {
  margin: 0;
  padding: 0;
}

button:focus {
  outline: 0;
}

.main {
  width: 100%;
}

* {
  box-sizing: border-box;
}

a:-webkit-any-link {
  text-decoration: none;
}

/* h1, h2 {
  padding-left: var(--left-padding-large);
  padding-right: var(--left-padding-large);
}

@media (min-width: 768px) {
  h1, h2 {
    padding-left: var(--left-padding-small);
    padding-right: var(--left-padding-small);
  }
}

@media (min-width: 1024px) {
  h1, h2 {
    padding-left: var(--left-padding-medium);
    padding-right: var(--left-padding-medium);
  }
} */

