@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PopUpContainer {
  background-color: rgba($Black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  display: flex;
  overflow: auto;
}

.PopUp {
  margin: auto;
  position: relative;
  height: 174px;
  width: 460px;
  background: $White;
  border-radius: 15px;
  // padding: 48px 72px 56px 48px;
  box-shadow: 0px 11px 15px rgba($Black, 0.2), 0px 24px 38px rgba($Black, 0.14),
    0px 9px 46px rgba($Black, 0.12);
  transition: all 0.5s;
}

.PopUpClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.PopUpTitle {
  @include title;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 21px;
  transition: all 0.5s;
}

.PopUpText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: $DarkBlack;
  padding: 48px 72px 56px 48px;
}

@media screen and (max-width: 470px) and (min-width: 300px) {
  .PopUp {
    width: 345px;
  }
  .PopUpText {
    font-size: 18px;
    padding-right: 50px;
  }
  .PopUpClose {
    top: 24px;
    right: 24px;
  }
}
