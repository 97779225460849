@import '@src/styles/colors.scss';

.RoadMapContainer {
  // height: 1435px;
  padding: 32px 21px 16px 40px;
  background-color: $LightWhite;
}

.RoadMapDashBoard {
  display: flex;
}

.RoadMapTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 16px;
}

.RoadMapText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: $DarkGrayish;
  margin-bottom: 32px;
}

.RoadMapEvents {
  flex: 20;
  display: flex;
  // flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 8px;
  position: relative;
  left: -21px;
}

.RoadMapEvent {
  margin-bottom: 32px;
}

.RoadMapEventTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $DarkBlack;
  margin-bottom: 4px;
}

.RoadMapEventText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: $DarkGrayish;
  max-width: 300px;
}

.RoadMapSvgContainer {
  flex: 1;
}

.Roadmap {
  position: relative;
  left: -21px;
}

.RoadMapMobile {
  position: relative;
  // top: -183px;
  // left: -21px;
  transition: all 0.5s;
  // width: 100%;
  // display: none;
}

.RoadMapMobileRus {
  // top: -220px;
}

@media screen and (max-width: 399.99px) {
  .RoadMapMobile {
    width: 100%;
  }
}

.RoadMapSVGContainer {
  display: flex;
  justify-content: center;
}

.Mobile {
  &RoadMapDashBoard {
    display: none;
    transition: all 0.5s;
  }
  &RoadMapEvents {
    flex: 20;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 8px;
    position: relative;
    left: -21px;
  }
  &RoadMapEvent {
    margin-bottom: 48px;
    // margin-bottom: 32px;
  }
  &RoadMapEventTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $DarkBlack;
    margin-bottom: 4px;
  }
  &RoadMapEventText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: $DarkGrayish;
  }
  &RoadMapSvgContainer {
    flex: 1;
  }
  &RoadMap {
    position: relative;
    left: -21px;
  }
}
