@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.MainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 184px 0 $defaultPadding;
  background: $LightWhite;
}

.MonetaTodayLink {
  margin: 70px 0 5px;
  // height: 25px;
}

.MonetaToday {
  height: 25px;
}

.LanguageContainer {
  margin-top: 43px;
  height: 53px;
}

.LanguageContainer:hover {
  cursor: pointer;
}

.LanguageImage {
  width: 67px;
  height: 53px;
}

@media (max-width: $responsive-desktop) {
  .MainContainer {
    padding-right: 70px;
  }
}

@media (max-width: $responsive-desktop-small) {
  .MainContainer {
    padding-right: 40px;
  }
}

@media (max-width: $responsive-tablet-small) {
  .MainContainer {
    padding-right: 22px;
    padding-bottom: 20px;
  }

  .MonetaTodayLink {
    margin-top: 40px;
    // height: 18px;
  }

  .MonetaToday {
    height: 18px;
    width: 180px;
  }

  .Lang {
    margin-top: 16px;
    height: 38px;
  }
}

@media (max-width: 355px) {
  .MainContainer {
    flex-direction: column;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .MonetaTodayLink {
    margin-top: 40px;
  }

  .MonetaToday {
    height: 18px;
    width: 160px;
  }
}

@media (max-width: 290px) {
  .MonetaToday {
    flex-direction: column;
    height: 18px;
    width: 130px;
  }

  .MonetaTodayLink {
    margin-top: 40px;
  }

  .LanguageImage {
    width: 50px;
    height: 40px;
  }
}
.LanguageToggle {
}
.SelectHeader {
  background: url('../../assets/svg/SelectBackground.svg') no-repeat center center;
  width: 64px;
  &:hover {
    opacity: 0.9;
  }
  padding: 0 12px;
}
.SelectContainer {
  box-shadow: none;
  height: 48px;
}
.SelectDropDownList {
  border: none;
}
.SelectListItem {
  height: 32px;
  padding-left: 12px;
  border-bottom: 1px solid rgb(229, 218, 198, 0.3);
  &:hover {
    background: rgb(229, 218, 198, 0.2);
  }
}
