@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.CookiesPopUpContainer {
  z-index: 9;
  background: #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  transition: all 0.5s;
  margin: 0 40px;
}
.CookiesPopUpText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  max-width: 1260px;
  padding: 30px 45px;
  transition: all 0.5s;
}
.ButtonContainer {
  transition: all 0.5s;
  margin-left: 25px;
  margin-right: 45px;
}
.Button {
  transition: all 0.5s;
}
@media screen and (max-width: 1000px) {
  .CookiesPopUpContainer {
    // margin: 0 25px;
  }
  .CookiesPopUpText {
    padding: 25px 0 25px 25px;
  }
  .ButtonContainer {
    margin-right: 25px;
  }
}
@media screen and (max-width: 800px) {
  .CookiesPopUpContainer {
    flex-direction: column;
    margin: 0 20px;
  }
  .CookiesPopUpText {
    font-size: 16px;
    line-height: 18px;
    padding: 20px 20px 0;
  }
  .ButtonContainer {
    margin: 20px 30px;
  }
  .ButtonText {
    font-size: 16px;
    line-height: 18px;
  }
}
@media screen and (max-width: 550px) {
  .Button {
    width: 250px;
  }
}
@media screen and (max-width: 450px) {
  .Button {
    width: 200px;
  }
}
@media screen and (max-width: 350px) {
  .Button {
    width: 150px;
  }
}
