@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
// @import '@src/styles/variables.scss';

.InvestTimeAndMoneyContainer {
  background: linear-gradient(180deg, $LightWhite 0%, rgba($LightWhite, 0) 152.08%);
}

.InvestTimeAndMoney {
  @include block_radius;
  height: 360px;
  width: 950px;
  padding: 64px 71px 88px 190px;
  background-color: $White;
  /* animation */
  transition: all 0.5s;
}

.InvestTimeAndMoneyTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  margin-bottom: 24px;
  transition: all 0.5s;
}

@media screen and (max-width: 1420px) and (min-width: 1024px) {
  .InvestTimeAndMoney {
    width: 800px;
    padding: 64px 41px 88px 70px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .InvestTimeAndMoney {
    width: 574px;
    height: 280px;
    padding: 48px 20px 50px 40px;
  }
  .InvestTimeAndMoneyTitle {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .InvestTimeAndMoney {
    width: 375px;
    height: 271px;
    padding: 32px 20px 50px 40px;
  }
  .InvestTimeAndMoneyTitle {
    font-size: 24px;
    margin-bottom: 17px;
  }
}
