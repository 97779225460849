@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ServiceButtonEng {
  // margin-top: 78px;
}

.Services {
  position: relative;
  // height: 808px; // Костя, это я отключил
  background-color: $LightWhite;
  padding: 96px $defaultPadding 350px;
  overflow: hidden;
  transition: all 0.5s;
}

.ServicesTitle {
  @include title;
  text-transform: uppercase;
  font-size: 60px;
  width: 910px;
}

.ServicesText {
  @include text;
  padding: 0 0 24px;
  width: 600px;
}

.ServicesContent {
  position: relative;
}

.SolarSystem {
  position: absolute;
  right: -60px;
  top: 27px;
  width: 1920px;
}

@media screen and (max-width: 1920px) and (min-width: 1400px) {
  .SolarSystem {
    width: calc(1920px - (1920px - 100%) * 0.8);
    top: calc(-20px - (1920px - 100%) * 0.03);
  }
}

@media screen and (max-width: 1400px) and (min-width: 560px) {
  .SolarSystem {
    width: calc(1500px - (1400px - 100%) * 0.6);
    top: calc(-15px - (1920px - 100%) * 0.03);
  }
  .ServicesTitle {
    font-size: 40px;
    width: 520px;
  }
  .ServicesText {
    font-size: 20px;
  }
}

@media screen and (max-width: 1060px) {
  .ServicesText {
    width: 400px;
  }
  .SolarSystem {
    width: calc(1000px);
    top: 27px;
  }
}
@media screen and (max-width: 900px) {
  .SolarSystem {
    width: calc(1000px);
    top: 27px;
  }
}

@media screen and (max-width: 750px) {
  .ServicesTitle {
    font-size: 30px;
    width: 520px;
  }
  .ServicesText {
    width: 400px;
  }
  .SolarSystem {
    width: 850px;
    top: -30px;
    // width: calc(900px - (750px - 100%)*0.6);
  }
}
@media screen and (max-width: 700px) {
  .SolarSystem {
    top: 65px;
    // position: relative;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1750px) {
  .Services {
    padding: 96px $defaultPadding 280px;
  }
}
@media screen and (max-width: 1749.99px) and (min-width: 1400px) {
  .Services {
    padding: 96px $defaultPadding 230px;
  }
}
@media screen and (max-width: 1399.99px) and (min-width: 1000px) {
  .Services {
    padding: 96px $defaultPadding 180px;
  }
}
@media screen and (max-width: 999.99px) {
  .Services {
    padding: 96px $defaultPadding 60px;
  }
}
