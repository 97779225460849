@import './colors.scss';

@mixin scroll {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin click-animation {
  transform: scale(0.98);
}

@mixin title {
  font-family: 'Raleway';
  font-weight: 800;
  line-height: 130%;
  color: $DarkBlack;
}

@mixin text {
  font-size: 22px;
  line-height: 180%;
  color: $DarkGrayish;
}

@mixin subtitle {
  @include title;
  font-size: 40px;
}

@mixin block_radius {
  border-top-right-radius: 90px;
}

@mixin font($size: 16px, $family: Roboto, $style: normal, $weight: normal, $line-height: 22px) {
  font: {
    size: $size;
    family: $family;
    style: $style;
    weight: $weight;
  }
  line-height: $line-height;
}

@mixin input {
  background: $Alabaster;
  box-shadow: inset 0px -0.5px 0px $Alto;
  @include font($weight: 400, $line-height: 24px);
  color: $Tundora;
  border: none;
  padding: 8px 12px;
  margin-bottom: 24px;
}

@mixin flex($display: flex, $justifyContent: center, $alignItems: center) {
  display: $display;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

// from HoneyWood
@mixin textBorder_1px ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color;
}

@mixin textBorder_1px_shadow ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
   0px  2px 0px $color,
}

@mixin textBorder_2px ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color;
}

@mixin textBorder_2px_shadow ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color,

  0px 4px 0px $color,
  2px 4px 0px $color,
  -2px 4px 0px $color,
}

@mixin textBorder_3px ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color,
  /* third layer at 3px */
  -3px -3px 0px $color,
  -2px -3px 0px $color,
   0px -3px 0px $color,
   2px -3px 0px $color,
   3px -3px 0px $color,
   3px -2px 0px $color,
   3px  0px 0px $color,
   3px  2px 0px $color,
   3px  3px 0px $color,
   2px  3px 0px $color,
   0px  3px 0px $color,
  -2px  3px 0px $color,
  -3px  3px 0px $color,
  -3px  2px 0px $color,
  -3px  0px 0px $color,
  -3px -2px 0px $color;
}

@mixin textBorder_3px_shadow ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color,
  /* third layer at 3px */
  -3px -3px 0px $color,
  -2px -3px 0px $color,
   0px -3px 0px $color,
   2px -3px 0px $color,
   3px -3px 0px $color,
   3px -2px 0px $color,
   3px  0px 0px $color,
   3px  2px 0px $color,
   3px  3px 0px $color,
   2px  3px 0px $color,
   0px  3px 0px $color,
  -2px  3px 0px $color,
  -3px  3px 0px $color,
  -3px  2px 0px $color,
  -3px  0px 0px $color,
  -3px -2px 0px $color,

  0px 6px 0px $color,
  3px 6px 0px $color,
  -3px 6px 0px $color,
}

@mixin textBorder_4px ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color,
  /* third layer at 3px */
  -3px -3px 0px $color,
  -2px -3px 0px $color,
   0px -3px 0px $color,
   2px -3px 0px $color,
   3px -3px 0px $color,
   3px -2px 0px $color,
   3px  0px 0px $color,
   3px  2px 0px $color,
   3px  3px 0px $color,
   2px  3px 0px $color,
   0px  3px 0px $color,
  -2px  3px 0px $color,
  -3px  3px 0px $color,
  -3px  2px 0px $color,
  -3px  0px 0px $color,
  -3px -2px 0px $color,
  /* four layer at 4px */
  -4px -4px 0px $color,
  -3px -4px 0px $color,
   0px -4px 0px $color,
   3px -4px 0px $color,
   4px -4px 0px $color,
   4px -3px 0px $color,
   4px  0px 0px $color,
   4px  3px 0px $color,
   3px  4px 0px $color,
   3px  4px 0px $color,
   0px  4px 0px $color,
  -3px  4px 0px $color,
  -4px  4px 0px $color,
  -4px  3px 0px $color,
  -4px  0px 0px $color,
  -4px -3px 0px $color;
}
@mixin textBorder_4px_shadow ($color: $white) {
  text-shadow: 
  /* first layer at 1px */
  -1px -1px 0px $color,
   0px -1px 0px $color,
   1px -1px 0px $color,
  -1px  0px 0px $color,
   1px  0px 0px $color,
  -1px  1px 0px $color,
   0px  1px 0px $color,
   1px  1px 0px $color,
  /* second layer at 2px */
  -2px -2px 0px $color,
  -1px -2px 0px $color,
   0px -2px 0px $color,
   1px -2px 0px $color,
   2px -2px 0px $color,
   2px -1px 0px $color,
   2px  0px 0px $color,
   2px  1px 0px $color,
   2px  2px 0px $color,
   1px  2px 0px $color,
   0px  2px 0px $color,
  -1px  2px 0px $color,
  -2px  2px 0px $color,
  -2px  1px 0px $color,
  -2px  0px 0px $color,
  -2px -1px 0px $color,
  /* third layer at 3px */
  -3px -3px 0px $color,
  -2px -3px 0px $color,
   0px -3px 0px $color,
   2px -3px 0px $color,
   3px -3px 0px $color,
   3px -2px 0px $color,
   3px  0px 0px $color,
   3px  2px 0px $color,
   3px  3px 0px $color,
   2px  3px 0px $color,
   0px  3px 0px $color,
  -2px  3px 0px $color,
  -3px  3px 0px $color,
  -3px  2px 0px $color,
  -3px  0px 0px $color,
  -3px -2px 0px $color,
  /* four layer at 4px */
  -4px -4px 0px $color,
  -3px -4px 0px $color,
   0px -4px 0px $color,
   3px -4px 0px $color,
   4px -4px 0px $color,
   4px -3px 0px $color,
   4px  0px 0px $color,
   4px  3px 0px $color,
   3px  4px 0px $color,
   3px  4px 0px $color,
   0px  4px 0px $color,
  -3px  4px 0px $color,
  -4px  4px 0px $color,
  -4px  3px 0px $color,
  -4px  0px 0px $color,
  -4px -3px 0px $color,
  0px 8px 0px $color,
  4px 8px 0px $color,
}

@mixin noselected {
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
