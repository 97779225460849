@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.MainContainer {
  position: relative;
  height: 390px;
  background: $BrownBlack;
}

.Container {
  position: relative;
  display: flex;
  padding: 64px 0 0 $defaultPadding;
  z-index: 1;
}

.LeftBlock {
  display: flex;
  flex-direction: column;
}

.Mail {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  color: $Gray;
  margin-bottom: 28px;
}

.MailIcon {
  padding: 5px 0 2px;
  margin-right: 12px;
}

.MailLink {
  text-decoration: none;
  color: $Gray;
}

.RightBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.TextLink {
  text-decoration: none;
}

.PrivacyLink {
  color: $Gray;
  cursor: pointer;
}

.Text {
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  color: $Gray;
  margin-bottom: 8px;
}

.Links {
  display: flex;
  font-size: 28px;
  line-height: 24px;
  margin-bottom: 24px;
}

.NameAndPolicy {
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
}

.LinkItem {
  width: 47px;
  color: rgba($White, 0.5);
}

.Code {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ButtonToTop {
  display: none;
  position: absolute;
  z-index: 1;
  top: -60px;
  right: 191px;
  cursor: pointer;
  &:active {
    @include click-animation;
  }
}

/* Временное решение, убираю кнопку */
@media screen and (max-width: 400px) {
  .ButtonToTop {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .MailText,
  .Text {
    word-break: break-all;
    margin-bottom: 16px;
  }
  .AppLinks {
    // flex-direction: column;
  }
  .AppStoreLink {
    // margin-bottom: 20px;
  }
}

.AppLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AppStoreLink {
}
.GooglePlayLink {
}
