@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  // width: 100%;
  // height: 100%;
  z-index: 10;
  width: 180px;
  height: 100px;
  // cursor: pointer;
}

.OuterContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.InnerContainer {
  position: absolute;
  left: 18.5px;
  top: 10px;
  width: calc(100% - 37px);
  height: calc(100% - 32px);
}

.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;

  display: flex;
  align-items: center;
  text-align: center;

  // disable select text 
  @include noselected();
  color: $white;
  fill: $white;
  &__MEDIUM {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
  }
  &__SMALL {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
  }
}

.TitleTransparent {
  color: transparent;
  position: absolute;
}

.ButtonOpacitySVG {
  display: none;
}

.ButtonSVGStyle:hover {
  cursor: pointer;

  .ButtonOpacitySVG {
    display: block;
  }
}

.ButtonSVGStyle:active {

  .ButtonOpacitySVG {
    display: none;
  }
  .ButtonInnerSVG {
    filter: url(#filter1_ddii_281_197);
    -webkit-filter: url(#filter1_ddii_281_197);
    & path {
      fill: url(#paint0_linear_281_197);
    }
  }
  .ButtonInnerSVG__small {
    filter: url(#filter1_ddii_281_197);
    -webkit-filter: url(#filter1_ddii_281_197);
    & path {
      fill: url(#paint0_linear_281_097);
    }
  }
}

.ActiveTransform:active {
  transform-origin: center center;
  transform: scale(0.997);
}

.SoonImage {
  position: absolute;
  z-index: inherit;
  top: -12px;
  left: 10px;
  width: 70px;
  &__MEDIUM {
    top: -12px;
    left: 10px;
    width: 100px;
  }
}

.ProgressIndicator {
  position: absolute;
  z-index: inherit;
  top: 18px;
  width: 30px;
  height: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  background-image: url('../../assets/images/BlockchainGamePage/progress_button.png');
  background-repeat: no-repeat;
  background-size:contain;
  animation: progressAnimation 2s infinite linear;
  &__MEDIUM {
    top: 32px;
    width: 50px;
    height: 50px;
  }
  &__SMALL {
    top: 18px;
    width: 30px;
    height: 30px;
  }
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}