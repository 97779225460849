@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.EconomyContainer {
  position: relative;
  height: 502px;
  padding: 64px $defaultPadding;
  background-color: $White;
}

.EconomyContainerTitle {
  @include title;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.EconomyCardsContainer {
  display: flex;
  justify-content: center;
}

.EconomyCards {
  @include scroll;
  display: flex;
  gap: 20px;
  margin: 0 20px 0 20px;
  padding-top: 8px;
}

@media screen and (max-width: 1700px) {
  .EconomyButton {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: $mt_yellow;
    min-width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  .EconomyButton:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .EconomyButtonImage {
    display: flex;
    width: 17px;
    height: 35px;
  }
}

@media screen and (max-width: 760px) {
  .EconomyCards {
    gap: 55px;
    margin: 0 115px 0 115px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}

@media screen and (max-width: 700px) {
  .EconomyCards {
    margin-left: 10px;
    margin-right: 10px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}

@media screen and (max-width: 560px) {
  .EconomyCards {
    margin-left: 10px;
    margin-right: 10px;
    gap: 90px;
  }
  .MainnetCardMarginRight {
    margin-right: 6px;
  }
}